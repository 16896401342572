import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./assets/styles/index.css";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import AdminRoute from "./components/AdminRoute";
import LoginScreen from "./screens/LoginScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import HomeScreen from "./screens/HomeScreen";
import TelekomPrivatniMobilni from "./components/TelekomPrivatniMobilni";
import TelekomDelovniMobilni from "./components/TelekomDelovniMobilni";
import A1Mobilni from "./components/A1Mobilni";
import A1Internet from "./components/A1Internet";
import TelekomDelovniFiksni from "./components/TelekomDelovniFiksni";
import TelekomPrivatniInternet from "./components/TelekomPrivatniInternet";
import TotalSum from "./components/TotalSum";
import SpecificOutcomesA1 from "./components/SpecificOutcomesA1";
import TelekomPrivatniPricing from "./components/TelekomPrivatniPricing";
import Compare from "./screens/Compare";
import CombinedPricingTable from "./screens/Compare";
import TelekomStand from "./components/TelekomStand";
import A1Stand from "./components/A1Stand";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />}>
        <Route path="" element={<AdminRoute />}>
          <Route index element={<HomeScreen />} />
          <Route
            path="telekom-b2c-mobile"
            element={<TelekomPrivatniMobilni />}
          />
          <Route
            path="telekom-b2c-internet"
            element={<TelekomPrivatniInternet />}
          />
          <Route
            path="telekom-b2b-mobile"
            element={<TelekomDelovniMobilni />}
          />
          <Route
            path="telekom-b2b-internet"
            element={<TelekomDelovniFiksni />}
          />
          <Route path="a1-mobile" element={<A1Mobilni />} />
          <Route path="a1-internet" element={<A1Internet />} />
          <Route path="telekom-standalone" element={<TelekomStand />} />
          <Route path="a1-standalone" element={<A1Stand />} />
          <Route path="market-price-chart" element={<CombinedPricingTable />} />
        </Route>
      </Route>
      <Route path="/login">
        <Route index element={<LoginScreen />} />
      </Route>
      <Route path="*" element={<NotFoundScreen />} />
    </>
  )
);

const rootElement = document.getElementById("root") as HTMLElement;

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    //<React.StrictMode>
    <RouterProvider router={router} />
    //</React.StrictMode>
  );
}

reportWebVitals();
