import axios from "axios";
import React, { useState, useEffect } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

function AdminRoute() {
  const [isAuthenticated, setIsAuthenticated] = useState("null");
  const location = useLocation();

  useEffect(() => {
    const checkToken = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/auth`, {
          withCredentials: true,
        });

        setIsAuthenticated("true");
      } catch (error) {
        setIsAuthenticated("false");
      }
    };

    checkToken();
  }, [location]);

  // Render nothing until authentication check is complete
  if (isAuthenticated === "null") {
    return null;
  }

  // Render based on isAuthenticated state
  if (isAuthenticated !== "true") {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}

export default AdminRoute;
