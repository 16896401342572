import React, { useEffect, useState } from "react";
import "../screens/kek.css";
import api from "../middleware/api";
import Logo from "./Logo";
import GoBackButton from "./GoBackButton";
import FileDownloadButton from "./FileDownloadButton";

interface Plan {
  date: string;
  name: string;
  priceWithoutTax: string;
  monthlyDiscountWithoutTax: string;
  subsidy: string;
  subsidyWithoutTax: string;
  descriptionHtmlMk: string;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear().toString();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

const formatDescription = (description: string) => {
  return description.split(",").map((line, index) => (
    <span key={index}>
      {line.trim()}
      <br />
    </span>
  ));
};

const A1Mobilni: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get<Plan[]>(
          "https://telekommarketprice.duckdns.org/api/CSV/A1Mobilni"
        );
        setPlans(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const isWithinDateRange = (date: string) => {
    if (!startDate || !endDate) {
      if (new Date(date).getMonth() + 1 === new Date().getMonth() + 1) {
        return true;
      } else {
        return false;
      }
    }
    const subtractOneDay = (date: Date): Date => {
      const result = new Date(date);
      result.setDate(result.getDate() - 1);
      return result;
    };
    const dateValue = new Date(date);
    return (
      dateValue >= subtractOneDay(new Date(startDate)) &&
      dateValue <= new Date(endDate)
    );
  };

  const isInSearch = (name: string) => {
    if (!searchTerm) return true;
    return name.split(" ").includes(searchTerm);
  };

  const filteredDates = plans
    .filter((item) => isWithinDateRange(item.date))
    .filter((item) => isInSearch(item.name));

  return (
    <div id="ewsxxcewqes" className="keks">
      <div className="aa">
        <div>
          <Logo className={["lcs"]} />
          <h1>A1 Mobile</h1>
          <div className="group">
            <svg className="icon" aria-hidden="true" viewBox="0 0 24 24">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              placeholder="Search"
              type="search"
              className="kekw"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
          <div className="date-picker">
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label htmlFor="endDate">End Date:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div>
          <FileDownloadButton
            koj={
              "/home/administrator/MarketPriceChart/Scripts/dataProd/A1_mobilni_tarifi.csv"
            }
          />
          <GoBackButton className={["dddd"]} />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Price Without Tax</th>
            <th>Monthly Discount Without Tax</th>
            <th>Subsidy</th>
            <th>Subsidy Without Tax</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {filteredDates.map((plan) => (
            <tr key={plan.name + plan.date}>
              <td>{formatDate(plan.date)}</td>
              <td className="llaol">{plan.name}</td>
              <td>{plan.priceWithoutTax} MKD</td>
              <td>{plan.monthlyDiscountWithoutTax} MKD</td>
              <td>{Math.floor(Number(plan.subsidy))} MKD</td>
              <td>{plan.subsidyWithoutTax} MKD</td>
              <td>{formatDescription(plan.descriptionHtmlMk)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default A1Mobilni;
