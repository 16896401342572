import axios from "axios";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const App: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/auth`, {
        withCredentials: true,
      });
    } catch (error) {
      navigate("/login");
    }
  };
  return (
    <div onClick={handleClick}>
      <Outlet />
    </div>
  );
};

export default App;
