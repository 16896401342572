import { useEffect, useState } from "react";
import api from "../middleware/api";
import Logo from "../components/Logo";
import GoBackButton from "../components/GoBackButton";
import Logo1 from "../components/LogoWhite";

interface TelekomPrivatni_Mobilni {
  date: string;
  name: string;
  oldPrice: string;
  price: string;
  oldestPromoPrice: string;
  familyBudget: string;
  benefits: string;
}

interface TelekomPrivatni_Internet {
  date: string;
  name: string;
  oldPrice: string;
  price: string;
  oldestPromoPrice: string;
  familyBudget: string;
  benefits: string;
}

interface A1Mobilni {
  date: string;
  name: string;
  priceWithoutTax: string;
  monthlyDiscountWithoutTax: string;
  subsidy: string;
  subsidyWithoutTax: string;
  descriptionHtmlMk: string;
}

interface A1Internet {
  date: string;
  name: string;
  priceWithoutTax: string;
  monthlyDiscountWithoutTax: string;
  subsidy: string;
  subsidyWithoutTax: string;
  descriptionHtmlMk: string;
}

interface Plan {
  date: string;
  name: string;
  oldPrice: string;
  price: string;
  oldPriceWithCloud: string;
  oldPriceWithoutCloud: string;
  priceWithClout: string;
  priceWithoutCloud: string;
  bonusToUse: string;
  bonusToUseWithCloud: string;
  bonusToUseWithoutCloud: string;
  priceWithoutCloudDiscountAmount: string;
  priceWithoutCloudWithoutDevice: string;
  characteristics: string;
}

interface CombinedData {
  date: string;
  combination: string;
  totalPrice: number;
  budget: number;
  netMargin: number;
}

const Compare: React.FC = () => {
  const [a1Mobilni, setA1Mobilni] = useState<A1Mobilni[]>([]);
  const [a1Internet, setA1Internet] = useState<A1Internet[]>([]);
  const [mobilniData, setMobilniData] = useState<TelekomPrivatni_Mobilni[]>([]);
  const [internetData, setInternetData] = useState<TelekomPrivatni_Internet[]>(
    []
  );
  const [telekomDelovniFiksni, setTelekomDelovniFiksni] = useState<Plan[]>([]);
  const [telekomDelovniMobilni, setTelekomDelovniMobilni] = useState<Plan[]>(
    []
  );
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Calculate specific outcomes for A1 data
  const calculateSpecificOutcomes = () => {
    const result: CombinedData[] = [];

    a1Mobilni.forEach((mobilni) => {
      a1Internet.forEach((internet) => {
        if (mobilni.date === internet.date) {
          if (
            (mobilni.name === "A1 Business Pro XS" || mobilni.name === "A1 Business Ultra XS" ) &&
            internet.name === "Business S"
          ) {
            const mobilniPriceXS =
              2 * parseFloat(mobilni.priceWithoutTax) -
              2 * parseFloat(mobilni.monthlyDiscountWithoutTax);
            const internetPriceS =
              parseFloat(internet.priceWithoutTax) -
              parseFloat(internet.monthlyDiscountWithoutTax);
            const totalPriceXS_S = mobilniPriceXS + internetPriceS;

            result.push({
              date: mobilni.date,
              combination: "Pro XS + S",
              totalPrice: totalPriceXS_S,
              budget: Number(mobilni.subsidy) * 2,
              netMargin:
                Math.ceil(
                  (Number(mobilni.subsidy) * 2) / 1.18 / 24 - totalPriceXS_S
                ) * -1,
            });
          }

          if (
            (mobilni.name === "A1 Business Pro S" || mobilni.name === "A1 Business Ultra S") &&
            internet.name === "Business M"
          ) {
            const mobilniPriceS =
              2 * parseFloat(mobilni.priceWithoutTax) -
              2 * parseFloat(mobilni.monthlyDiscountWithoutTax);
            const internetPriceM =
              parseFloat(internet.priceWithoutTax) -
              parseFloat(internet.monthlyDiscountWithoutTax);
            const totalPriceS_M = mobilniPriceS + internetPriceM;

            result.push({
              date: mobilni.date,
              combination: "Pro S + M",
              totalPrice: totalPriceS_M,
              budget: Number(mobilni.subsidy) * 2,
              netMargin:
                Math.ceil(
                  (Number(mobilni.subsidy) * 2) / 1.18 / 24 - totalPriceS_M
                ) * -1,
            });
          }

          if (
            (mobilni.name === "A1 Business Pro M" || mobilni.name === "A1 Business Ultra M") &&
            internet.name === "Business L"
          ) {
            const mobilniPriceM =
              2 * parseFloat(mobilni.priceWithoutTax) -
              2 * parseFloat(mobilni.monthlyDiscountWithoutTax);
            const internetPriceL =
              parseFloat(internet.priceWithoutTax) -
              parseFloat(internet.monthlyDiscountWithoutTax);
            const totalPriceM_L = mobilniPriceM + internetPriceL;

            result.push({
              date: mobilni.date,
              combination: "Pro M + Business L",
              totalPrice: totalPriceM_L,
              budget: Number(mobilni.subsidy) * 2,
              netMargin:
                Math.ceil(
                  (Number(mobilni.subsidy) * 2) / 1.18 / 24 - totalPriceM_L
                ) * -1,
            });
          }
        }
      });
    });

    return result;
  };

  // Calculate pricing for Telekom data
  const calculatePricing = () => {
    const result: CombinedData[] = [];

    mobilniData.forEach((mobilni) => {
      internetData.forEach((internet) => {
        if (
          (mobilni.date === internet.date && mobilni.name === internet.name) ||
          (mobilni.date === internet.date &&
            mobilni.name === "Unlimited" &&
            internet.name === "L")
        ) {
          const mobilniPrice = 2 * parseFloat(mobilni.price);
          const internetPrice = parseFloat(internet.price);
          const totalPrice = mobilniPrice + internetPrice;

          result.push({
            date: mobilni.date,
            combination: "TB2C " + internet.name,
            totalPrice: totalPrice,
            budget: Number(mobilni.familyBudget) * 2,
            netMargin:
              Math.floor(
                (Number(mobilni.familyBudget) * 2) / 1.18 / 24 - totalPrice
              ) * -1,
          });
        }
      });
    });

    return result;
  };

  // Calculate total prices from Telekom Delovni data
  const calculateTotalPrices = () => {
    const result: CombinedData[] = [];

    const totals: Record<
      string,
      Record<
        string,
        {
          mobilniPrice: number;
          fiksniPrice: number;
          totalPrice: number;
          bonusToUse: number;
        }
      >
    > = {};

    telekomDelovniMobilni.forEach((mobilni) => {
      const date = mobilni.date; // Extract date part
      if (!totals[date]) {
        totals[date] = {};
      }
      if (!totals[date][mobilni.name]) {
        totals[date][mobilni.name] = {
          mobilniPrice: 0,
          fiksniPrice: 0,
          totalPrice: 0,
          bonusToUse: 0,
        };
      }
      totals[date][mobilni.name].mobilniPrice = parseFloat(
        mobilni.priceWithClout
      );
      totals[date][mobilni.name].bonusToUse = parseFloat(mobilni.bonusToUse);
    });

    telekomDelovniFiksni.forEach((fiksni) => {
      const date = fiksni.date; // Extract date part
      if (!totals[date]) {
        totals[date] = {};
      }
      if (!totals[date][fiksni.name]) {
        totals[date][fiksni.name] = {
          mobilniPrice: 0,
          fiksniPrice: 0,
          totalPrice: 0,
          bonusToUse: 0,
        };
      }
      totals[date][fiksni.name].fiksniPrice = parseFloat(fiksni.oldPrice);
    });

    Object.keys(totals).forEach((date) => {
      Object.keys(totals[date]).forEach((name) => {
        const { mobilniPrice, fiksniPrice } = totals[date][name];
        const totalPrice = mobilniPrice * 2 + fiksniPrice;
        result.push({
          date: date,
          combination: name,
          totalPrice: totalPrice,
          budget: Number(totals[date][name].bonusToUse) * 2,
          netMargin: Math.floor(
            ((Number(totals[date][name].bonusToUse) * 2) / 1.18 / 24 -
              totalPrice) *
              -1
          ),
        });
      });
    });

    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response132x3 = await api.get<A1Mobilni[]>(
          "https://telekommarketprice.duckdns.org/api/CSV/A1Mobilni"
        );
        setA1Mobilni(response132x3.data);

        const response44 = await api.get<A1Internet[]>(
          "https://telekommarketprice.duckdns.org/api/CSV/A1Internet"
        );
        setA1Internet(response44.data);

        const response12 = await api.get<TelekomPrivatni_Mobilni[]>(
          "https://telekommarketprice.duckdns.org/api/CSV/TelekomPrivatni_Mobilni"
        );
        setMobilniData(response12.data);

        const response123 = await api.get<TelekomPrivatni_Internet[]>(
          "https://telekommarketprice.duckdns.org/api/CSV/TelekomPrivatni_Internet"
        );
        setInternetData(response123.data);

        const responseFiksni = await api.get<Plan[]>(
          "https://telekommarketprice.duckdns.org/api/CSV/TelekomDelovni_Fiksni"
        );
        setTelekomDelovniFiksni(responseFiksni.data);

        const responseMobilni = await api.get<Plan[]>(
          "https://telekommarketprice.duckdns.org/api/CSV/TelekomDelovni_Mobilni"
        );
        setTelekomDelovniMobilni(responseMobilni.data);

        setLoading(false);
      } catch (error) {
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Combine all results
  const combinedData = calculateSpecificOutcomes()
    .concat(calculatePricing())
    .concat(calculateTotalPrices());

  // Extract unique dates and combinations
  const dates = Array.from(new Set(combinedData.map((item) => item.date)));
  const combinations = Array.from(
    new Set(combinedData.map((item) => item.combination))
  );

  const desiredOrder = [
    "Essential",
    "Pro XS + S",
    "TB2C S",
    "Standard",
    "Pro S + M",
    "TB2C M",
    "Professional",
    "Pro M + Business L",
    "TB2C L",
  ];

  const orderMap = new Map(desiredOrder.map((item, index) => [item, index]));

  // Sort the combinations array based on the index from the orderMap
  const sortedCombinations = combinations.slice().sort((a, b) => {
    return (orderMap.get(a) ?? Infinity) - (orderMap.get(b) ?? Infinity);
  });

  // Create a map for easy access
  // const dataMap = combinedData.reduce((acc, item) => {
  //   if (!acc[item.date]) {
  //     acc[item.date] = {};
  //   }
  //   acc[item.date][item.combination] = item;
  //   return acc;
  // }, {} as Record<string, Record<string, CombinedData>>);

  const isWithinDateRange = (date: string) => {
    if (!startDate || !endDate) {
      if (new Date(date).getMonth() + 1 === new Date().getMonth() + 1) {
        return true;
      } else {
        return false;
      }
    }
    const subtractOneDay = (date: Date): Date => {
      const result = new Date(date);
      result.setDate(result.getDate() - 1);
      return result;
    };
    const dateValue = new Date(date);
    return (
      dateValue >= subtractOneDay(new Date(startDate)) &&
      dateValue <= new Date(endDate)
    );
  };

  const filteredDates = dates.filter(isWithinDateRange);

  const dataMap = combinedData.reduce((acc, item) => {
    if (isWithinDateRange(item.date)) {
      if (!acc[item.date]) {
        acc[item.date] = {};
      }
      acc[item.date][item.combination] = item;
    }
    return acc;
  }, {} as Record<string, Record<string, CombinedData>>);

  return (
    <div id="eewew" className="keks">
      <div className="aa">
        <div>
          <Logo className={["lcs"]} />
          <h1>Market Price Chart</h1>
        </div>
        <div className="date-picker kekzzzz">
          <label htmlFor="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label htmlFor="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <GoBackButton className={["dddd"]} />
      </div>
      <table className="topKeks">
        <thead>
          <tr>
            <th>Date</th>
            {sortedCombinations.map((combination) => (
              <th key={combination}>
                <span id="kzx">
                  {!combination.includes("+") ? (
                    <Logo1 className={["lcs1"]} />
                  ) : (
                    <Logo1 className={["lcs2"]} which={1} />
                  )}
                  {combination}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredDates.map((date) => (
            <tr key={date}>
              <td>{date.split("T")[0].split("-").reverse().join("-")}</td>
              {sortedCombinations.map((combination) => {
                const item = dataMap[date]?.[combination];
                return (
                  <td key={combination}>
                    {item ? (
                      <>
                        <div>Total Price: {item.totalPrice} MKD</div>
                        <div>Budget: {item.budget} MKD</div>
                        <div>Net Margin: {item.netMargin} MKD</div>
                      </>
                    ) : (
                      "N/A"
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Compare;
