import { ReactComponent as LogoTwhite } from "../assets/T_logo_carrier_surface_rgb_n.svg";
import A1Logo from "../assets/32px-Logo_of_A1.svg.png";
import stylesLogo from "../assets/styles/logo.module.css";

interface LogoProps {
  className?: string[];
  which?: number;
}

const Logo1: React.FC<LogoProps> = ({ className = [], which = 0 }) => {
  const classNames = className
    .map((className) => stylesLogo[className])
    .filter(Boolean);

  return (
    <div className={classNames.join(" ")}>
      {which === 0 ? <LogoTwhite /> : <img src={A1Logo} alt="" />}
    </div>
  );
};

export default Logo1;
