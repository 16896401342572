import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/styles/homeScreen.module.css";
import Logo from "../components/Logo";
import LogoutButton from "../components/LogoutButton";

const HomeScreen: React.FC = () => {
  return (
    <>
      <LogoutButton />
      <div className={styles.container}>
        <Logo className={["default", "mB"]} />
        <div className={styles.buttonWrapper}>
          <Link to="/telekom-b2c-mobile" className={styles.a}>
            Telekom B2C Mobile
          </Link>
          <Link to="/telekom-b2c-internet" className={styles.a}>
            Telekom B2C Internet
          </Link>
          <Link to="/telekom-b2b-mobile" className={styles.a}>
            Telekom B2B Mobile
          </Link>
          <Link to="/telekom-b2b-internet" className={styles.a}>
            Telekom B2B Internet
          </Link>
          <Link to="/a1-mobile" className={styles.a}>
            A1 Mobile
          </Link>
          <Link to="/a1-internet" className={styles.a}>
            A1 Internet
          </Link>
          <Link to="/telekom-standalone" className={styles.a}>
            Telekom Standalone
          </Link>
          <Link to="/a1-standalone" className={styles.a}>
            A1 Standalone
          </Link>
          <Link to="/market-price-chart" className={styles.a}>
            Market Price Chart
          </Link>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
