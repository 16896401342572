import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import styles from "../assets/styles/loginScreen.module.css";
import Loader from "../components/Loader";
import Logo from "../components/Logo";
import api from "../middleware/api";

const LoginScreen: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const odlogiraj = async () => {
      await api.post("/api/User/logout", {});
    };
    if (location.state?.od === "login") {
      odlogiraj();
      window.history.replaceState({}, "");
    }
  }, [location]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === "username") {
      const valueWithoutSpaces = value.replace(/\s/g, "");
      setUsername(valueWithoutSpaces);
      return;
    }
    if (name === "password") setPassword(value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    if (
      !/^[a-zA-Z0-9@.]+$/.test(username) ||
      !/^[a-zA-Z0-9]+$/.test(password)
    ) {
      setError("Invalid Credentials");
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post(
        `https://telekommarketprice.duckdns.org/api/User/login`,
        {
          Name: username,
          Password: password,
        },
        { withCredentials: true }
      );
      if (response.status === 200) {
        navigate("/");
      } else {
        setError("Login failed");
      }
    } catch (error) {
      setError("Invalid Credentials");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Logo className={["default", "mB"]} />
      <div className={styles.anotherWrapper}>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <input
              name="username"
              type="text"
              autoComplete="off"
              className={styles.input}
              value={username}
              onChange={handleInputChange}
              required
            />
            <label className={styles.userLabel}>Username</label>
          </div>
          <div className={styles.inputGroup}>
            <input
              name="password"
              type="password"
              autoComplete="off"
              className={styles.input}
              value={password}
              onChange={handleInputChange}
              required
            />
            <label className={styles.userLabel}>Password</label>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            error && <div className={styles.error}>{error}</div>
          )}
          <div className={styles.lol}>
            <button
              disabled={isLoading}
              className={isLoading ? styles.unavailableButton : styles.kopce}
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
