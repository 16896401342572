import api from "../middleware/api";
import styles from "../assets/styles/kopceto.module.css";

const FileDownloadButton: React.FC<{ koj: string }> = ({ koj }) => {
  const handleDownload = async () => {
    try {
      const response = await api.get("/api/File/download", {
        params: {
          filePath: koj,
        },
        responseType: "blob", // important to set responseType to 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", koj.split("/")[6]);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <button className={styles.kopceto} onClick={handleDownload}>
      Download CSV File
    </button>
  );
};

export default FileDownloadButton;
